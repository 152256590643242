.Invoice { 
    --scaleFactor: 3.5;
    display: grid; 
    grid-template-columns: calc(114px * var(--scaleFactor)) calc(14px * var(--scaleFactor)) calc(106px * var(--scaleFactor)) calc(132px * var(--scaleFactor)); 
    grid-template-rows: calc(16px * var(--scaleFactor)) calc(15px * var(--scaleFactor)) calc(10px * var(--scaleFactor)) calc(23px * var(--scaleFactor)) calc(6.5px * var(--scaleFactor)) calc(17px * var(--scaleFactor)) calc(25px * var(--scaleFactor)) calc(33px * var(--scaleFactor)) calc(35.5px * var(--scaleFactor)); 
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
    text-align: left;
    
    }

    .CutHorizotalView { grid-area: 1 / 1 / 2 / 5; } 
    .CutVerticalView { grid-area: 1 / 2 / 10 / 3; } 
    .HeaderLeft { grid-area: 2 / 1 / 3 / 2; } 
    .HeaderCenter { grid-area: 2 / 3 / 3 / 4; } 
    .AddressReceiverLeft { grid-area: 3 / 1 / 5 / 2; } 
    .AddressReceiverRight { grid-area: 2 / 4 / 5 / 5; }
    .ReferenzRight { grid-area: 5 / 4 / 7 / 5; }
    .ReferenzLeft { grid-area: 6 / 1 / 7 / 2; } 
    .FromAddressLeft { grid-area: 7 / 1 / 8 / 2;} 
    .AdditionalInformation { grid-area: 7 / 4 / 8 / 5; } 
    .FromAddressRight { grid-area: 8 / 4 / 9 / 5; } 
    .QRCodeView { 
        grid-area: 4 / 3 / 9 / 4;
        padding-right: 40px;
        transform: translateX(-5px);
     } 
    .AmountLeft 
    {   grid-area: 9 / 1 / 10 / 2;
        display: grid; 
        grid-template-columns: repeat(2, 1fr); 
        grid-template-rows: 1fr; 
        grid-column-gap: 0px;
        grid-row-gap: 0px;  
    } 

    .AmountRight 
    { 
        grid-area: 9 / 3 / 10 / 4;
        display: grid; 
        grid-template-columns: repeat(2, 1fr); 
        grid-template-rows: 1fr; 
        grid-column-gap: 0px;
        grid-row-gap: 0px;  
    } 

    .AmountRight_ColumnLeft
    {
        grid-area: 1 / 1 / 2 / 2;
    }

    .AmountRight_ColumnRight
    {
        grid-area: 1 / 2 / 2 / 3;
    }

    h5{
        font-size: 25px;
        padding-left: 0px;
        margin: 2px;
    }
    h6{
        font-size: 20px;
        padding: 2px;
        margin: 2px;
        padding-left: 0px;
    }

    h7{
        font-size: 15px;
        padding-left: 0px;
    }

    .TextBox_Medium
    {
        font-size: 20px;
        padding-left: 0px;
        width: 300px;
        padding: 0px;
        margin: 1px;
        transition: all 0.3s ease 0s;
        background-color: transparent;
        border-style: solid;
        border-color: #999999;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    }

    .TextBox_Medium:hover
    {
        transform: translateY(-2px);
    }

    .TextBox_Medium:focus
    {
        color: #242424;
        transform: translateY(-2px);
    }



    label{
        font-size: 20px;
        padding-left: 0px;
    }
    br{
        font-size: 1px;
        padding-left: 0px;
        height: 0.5px;
    }

    .CutHorizotal
    {
        height: 20px;
        width: 1400px;
    }

    .CutVertical
    {
        height: 750px;
        width: 21px;
    }


    .DownloadButton
    {
        height: 50px;
        width: 250px;
        font-size: 30px;
        border-style: solid;
        background-color: #2196F3;
        color: #FFFFFF;
        border-color: transparent;
        border-radius: 2px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        transform: translateY(8px);
        transition: all 0.3s ease 0s;
    }

    .DownloadButton:hover
    {
       box-shadow: 0px 15px 20px rgba(33, 150, 243, 0.4);
        transform: translateY(1px);
    }

    .DropDown
    {
        font-size: 20px;
        padding-left: 0px;
        padding: 0px;
        margin: 1px;
        transition: all 0.3s ease 0s;
        background-color: transparent;
        border-style: solid;
        border-color: #999999;
        border-radius: 0px;
    }

    .space
    {
        height: 30px;
    }
    

    .DropDown:hover
    {
        transform: translateY(-2px);
    }

    .IconImgs
    {
        width: 30px;
        height: 30px;
        padding: 0px;
        position: absolute;
    }

    .Controls
    {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }

    .Controls_Titel
    {
        grid-area: 1 / 1 / 2 / 2;
       text-align: left;
    }


    .Controls_DownloadButton
    {
        grid-area: 1 / 5 / 2 / 6;
        align-items: center;
    }

    .EmptySettings
    {
        grid-area: 1 / 2 / 2 / 5;
    }

    .ControlsTitel_Text
    {
        text-align: left;
    }