.RegisterPage
{

} 

.InputField
{
    display: grid; 
    grid-template-columns: repeat(2, 200px) 30px; 
    grid-template-rows: repeat(8, 1fr); 
    grid-column-gap: 15px;
    grid-row-gap: 10px; 
    text-align: left;
    transform: translateX(30px);
}

.TextBox_Half
{
    width: 100%;
    height: 50px;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 5px;
    transition: all 0.3s ease 0s;
}

.TextBox_Full
{
    width: 100%;
    height: 50px;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 5px;
    transition: all 0.3s ease 0s;
}

.TextBox_Half:hover
{
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}


.TextBox_Full:hover
{
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.FamilyNameField { grid-area: 1 / 1 / 2 / 2; } 
.NameField { grid-area: 1 / 2 / 2 / 3; } 
.StreetField { grid-area: 2 / 1 / 3 / 3; } 
.ZIPCodeField { grid-area: 3 / 1 / 4 / 2; } 
.CityField { grid-area: 3 / 2 / 4 / 3; } 
.IBANField { grid-area: 4 / 1 / 5 / 3; } 
.CountryField { grid-area: 5 / 1 / 6 / 3; } 
.EmailField { grid-area: 6 / 1 / 7 / 3; } 
.PasswordField { grid-area: 7 / 1 / 8 / 3; } 
.SubmitField { grid-area: 8 / 1 / 9 / 3; } 
.IbanVerification 
{ 
    grid-area: 4 / 3 / 5 / 4; 
    width: 100%;
    transform: translateY(32px);
} 
.EmailVerification 
{ 
    grid-area: 6 / 3 / 7 / 4; 
    width: 100%;
    transform: translateY(32px);
} 

.TextBoxLbl_Register
{
    font-size: 15px;
    
}

.Btn_RegisterSubmit
{
    width: 415px;
    font-size: 25px;
    border-style: solid;
    background-color: #2196F3;
    color: #FFFFFF;
    border-color: transparent;
    border-radius: 2px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    margin-top: 20px;
}

.Btn_RegisterSubmit:hover
{
    box-shadow: 0px 15px 20px rgba(33, 150, 243, 0.4);
    transform: translateY(-2px);
}

.ErrorEmailUsed
{
    margin-left: 10px;
    font-size: 15px;
    font-weight: bold;
    color: #F56451;
}