.App {
  text-align: center;

}

.App-header {
  background-color:#f1eeee ;
  height: 150px;
}

body{
  background-color:#EEEEEE ;
}

.App
{
  display: grid;
  place-items: center;
}

.FooterMain
{
  padding-top: 100px;
  width: 1281px;
}
