.TextField{
    width: 300px;
    height: 50px;
    font-size: 20px;
    margin: 5px;
}

.Btn_Login
{
    width: 300px;
    font-size: 25px;
    border-style: solid;
    background-color: #2196F3;
    color: #FFFFFF;
    border-color: transparent;
    border-radius: 2px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
}

.Btn_Login:hover
{
    box-shadow: 0px 15px 20px rgba(33, 150, 243, 0.4);
    transform: translateY(-2px);
}

.LoginPage
{
    padding-top: 300px;
    padding-bottom: 300px;
}


.Login_IconImgs
{
    width: 30px;
    height: 30px;
    padding: 0px;
    margin-top: 16px;
    position: absolute;
}

.errorText
{
    font-size: 20px;
    color: #F56451;
    font-weight:normal;
}

.LoginField
{
    display: grid; 
    grid-template-columns: 1fr 2px 1fr; 
    grid-template-rows: 1fr; 
    grid-column-gap: 100px;
    grid-row-gap: 0px;
}

.LoginSide
{
    grid-area: 1 / 1 / 2 / 2;
}

.VerticalSeparator
{
    grid-area: 1 / 2 / 2 / 3;
    background-color: #FFFFFF;
}

.RegisterSide
{   
    text-align: left;
    grid-area: 1 / 3 / 2 / 4;
}

.IconRegister
{
    height: 45px;
    transform: translateY(15px);
    padding-top: 0px;
}

.Btn_Register
{
    width: 300px;
    font-size: 25px;
    border-style: solid;
    background-color: #2196F3;
    color: #FFFFFF;
    border-color: transparent;
    border-radius: 2px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    margin-top: 20px;
}

.Btn_Register:hover
{
    box-shadow: 0px 15px 20px rgba(33, 150, 243, 0.4);
    transform: translateY(-2px);
}

.RegisterTitel
{
    padding: 0px;
    margin: 0px;
}